import React, { useState, useEffect } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import logo from "../../assets/logo.svg";
import SetOG from "../Extras/SetOG";
export default function SignUp() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [first_name, setFirst_name] = useState("");
    const [last_name, setLast_name] = useState("");
    const submitlogin = (e) => {
        e.preventDefault();
        const newRegister = { username, password, first_name, last_name };
        fetch(process.env.REACT_APP_API_URL + "/api/register", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newRegister),
        })
            .then((res) => res.json())
            .then((result) => {
                window.alert("Account Created Successfully");
                window.location.href = "/login";
            })
            .catch((err) => {
                window.alert(err);
            });
    };
    useEffect(() => {
        if (localStorage.getItem("token")) {
            window.location.href = "/dashboard";
        }
    }, []);
    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <SetOG title="Sign up on illiyn.com" />
                <div className="my-16 w-full max-w-md space-y-8">
                    <div>
                        <img
                            className="mx-auto h-12 w-auto"
                            src={logo}
                            alt="Workflow"
                        />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            Create a new account
                        </h2>
                    </div>
                    <form
                        className="mt-8 space-y-6"
                        onSubmit={(e) => {
                            submitlogin(e);
                        }}
                        method="POST"
                    >
                        <input
                            type="hidden"
                            name="remember"
                            defaultValue="true"
                        />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div class="mb-6">
                                <label
                                    for="email"
                                    class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                >
                                    Your First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstname"
                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Your First Name"
                                    required
                                    onChange={(e) => {
                                        setFirst_name(e.target.value);
                                    }}
                                />
                            </div>
                            <div class="">
                                <label
                                    for="email"
                                    class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                >
                                    Your Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastname"
                                    class="mb-6 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Your Last Name"
                                    required
                                    onChange={(e) => {
                                        setLast_name(e.target.value);
                                    }}
                                />
                            </div>

                            <div class="mb-6">
                                <label
                                    for="username"
                                    class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                >
                                    Username
                                </label>
                                <input
                                    type="text"
                                    id="username"
                                    class="mb-6 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Your Username"
                                    required
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                />
                            </div>

                            <div class="mb-6">
                                <label
                                    for="password"
                                    class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                >
                                    Your Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Your Password"
                                    required
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                    htmlFor="remember-me"
                                    className="ml-2 block text-sm text-gray-900"
                                >
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm">
                                <a
                                    href="/login"
                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                    Already have an Account? Login here.
                                </a>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon
                                        className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                        aria-hidden="true"
                                    />
                                </span>
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
