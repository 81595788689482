import React from "react";
import SetOG from "../Extras/SetOG";
import ShopBG from "./../../assets/images/shop.jpg";
export default function Shop() {
    return (
        <div className="mb-8">
            <SetOG title="Shop | illiyn" />
            <div
                style={{ backgroundImage: `url(${ShopBG})` }}
                className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-lg"
            >
                <div className="z-10 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-40">
                    <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold text-white">
                        Shop
                    </h1>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                {" "}
                <div className="w-100 card h-64 rounded-xl border-2 border-black bg-gradient-to-tr from-sky-200 to-sky-500 text-4xl  transition-all duration-500 hover:text-6xl">
                    <a href="/shop/men">
                        <div className="flex h-full w-full  items-center justify-center font-bold">
                            Men
                        </div>
                    </a>
                </div>
                <div className="w-100 card h-64 rounded-xl border-2 border-black bg-gradient-to-tr from-rose-200 to-rose-500 text-4xl  transition-all duration-500 hover:text-6xl">
                    <a href="/shop/women">
                        <div className="flex h-full w-full  items-center justify-center font-bold">
                            Women
                        </div>
                    </a>
                </div>
                <div className="w-100 card h-64 rounded-xl border-2 border-black bg-gradient-to-tr from-pink-200 to-pink-500 text-4xl  transition-all duration-500 hover:text-6xl">
                    <a href="/shop/kids">
                        <div className="flex h-full w-full  items-center justify-center font-bold">
                            Kids
                        </div>
                    </a>
                </div>
                <div className="w-100 card h-64 rounded-xl border-2 border-black bg-[#FECB96] text-4xl transition-all  duration-500 hover:text-6xl">
                    <a href="/shop/other">
                        <div className="flex h-full w-full  items-center justify-center font-bold">
                            Other
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}
