export default function SetOG({ title, image, description = "illiyn.com" }) {
    document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", image);
    document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", title);
    document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", description);
    return null;
}
