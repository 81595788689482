import React, { useEffect, useState } from "react";
import home_image from "./../assets/images/home_image.jpg";
import dark_quran from "./../assets/images/dark_quran.jpg";
import AskAnImam from "./../assets/images/AskAnImam.webp";
import Shop from "./../assets/images/shop.jpg";
import FreePDF from "./../assets/images/FREEpdf.webp";
import AllBlogMustReadBook from "./../assets/images/AllBlogMustReadBook.webp";

import SetOG from "./Extras/SetOG";
export default function Home() {
    const blog_image_url = process.env.REACT_APP_API_URL;
    const [isLoaded, setIsLoaded] = useState(false);
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        const api_url = process.env.REACT_APP_API_URL + "/api/recent-blog/";
        fetch(api_url)
            .then((res) => res.json())
            .then(
                (result) => {
                    setBlog(result);
                    setIsLoaded(true);
                },
                (error) => {
                    console.log(error);
                }
            );
    }, []);
    return (
        <div className="w-full">
            <SetOG title="Home | illiyn" />
            <div
                style={{
                    backgroundImage: `url(${home_image})`,
                }}
                className="card bg-left-center my-8 w-full overflow-hidden rounded-3xl bg-cover py-32 px-16 shadow-xl md:bg-right"
            >
                <div className="prose w-full">
                    <h1 className="text-5xl font-bold text-white">illiyn</h1>
                    <h6 className="text-md text-white">
                        This is an Amazon Affiliate Marketing Base Site. We
                        offer the top quality items at competitive pricing. All
                        of the group's dividends are given to charity.
                        <br />
                        So, With considerable virtue, collect the product you
                        need.
                    </h6>
                </div>
            </div>
            {isLoaded === true ? (
                <div className="mb-8 grid grid-cols-1 gap-4 md:grid-cols-5">
                    {blog.slice(0, 1).map((item) => (
                        <a
                            key={item.slug}
                            href={"/blog/" + item.slug}
                            className="group h-full md:col-span-3 md:row-span-2"
                        >
                            <div
                                style={{
                                    backgroundImage: `url(${
                                        blog_image_url + item.image
                                    })`,
                                }}
                                className="h-64 overflow-hidden rounded-xl bg-cover shadow-xl md:h-full"
                            >
                                <div className="flex h-full flex-col justify-end bg-gradient-to-t from-slate-700 p-8 text-white backdrop-filter transition-all duration-1000 group-hover:bg-slate-700 group-hover:bg-opacity-40 group-hover:backdrop-blur">
                                    <span className="font-bold">
                                        {item.category}
                                    </span>
                                    <h1 className="mt-6 text-2xl font-bold transition-all duration-1000 group-hover:text-6xl md:text-5xl">
                                        {item.title}
                                    </h1>
                                </div>
                            </div>
                        </a>
                    ))}
                    {blog.slice(1).map((item) => (
                        <a
                            key={item.slug}
                            href={"/blog/" + item.slug}
                            className="group h-full md:col-span-2 md:row-span-1"
                        >
                            <div
                                style={{
                                    backgroundImage: `url(${
                                        blog_image_url + item.image
                                    })`,
                                }}
                                className="overflow-hidden rounded-xl bg-cover bg-center shadow-xl"
                            >
                                <div className="flex h-64 flex-col justify-end bg-opacity-75 bg-gradient-to-t from-slate-700 p-8 text-white transition-all duration-1000 group-hover:bg-slate-700 group-hover:bg-opacity-40 group-hover:backdrop-blur">
                                    <h4 className="font-bold">
                                        {item.category}
                                    </h4>
                                    <h1 className="mt-2 text-2xl font-bold transition-all duration-1000 group-hover:text-3xl">
                                        {item.title}
                                    </h1>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            ) : (
                <div></div>
            )}

            <div className="grid grid-cols-1 gap-4 pb-8 md:grid-cols-2 lg:grid-cols-3">
                <div
                    style={{ backgroundImage: `url(${AskAnImam})` }}
                    className=" rounded-2xl border-2 border-black bg-cover shadow-xl md:col-span-2"
                >
                    <div className="card flex h-full flex-col items-center justify-center rounded-2xl bg-gray-800 bg-opacity-50 p-4 px-4 py-20 text-white backdrop-filter hover:backdrop-blur">
                        <h1 className="text-2xl font-bold">Ask Our Imam</h1>
                        <p className="py-6 text-center font-semibold">
                            Your questions are answered by qualified imams.
                        </p>
                        <a
                            className="bg-primary rounded-lg px-4 py-2 text-white"
                            href="/ask-an-imam"
                        >
                            Ask a Question
                        </a>
                    </div>
                </div>
                <div
                    style={{ backgroundImage: `url(${Shop})` }}
                    className="rounded-2xl border-2 border-black bg-cover"
                >
                    <div className="card flex h-full flex-col items-center justify-center rounded-2xl bg-gray-800 bg-opacity-70 px-4 py-20 text-white shadow-xl backdrop-filter hover:backdrop-blur md:col-span-1">
                        <h1 className="text-2xl font-bold">Affiliate Shop</h1>
                        <p className="py-6 text-center">
                            Your questions are answered by qualified imams.
                        </p>
                        <a
                            className="bg-primary rounded-lg px-4 py-2 text-white"
                            href="/shop"
                        >
                            See Products
                        </a>
                    </div>
                </div>
                <div
                    style={{ backgroundImage: `url(${AllBlogMustReadBook})` }}
                    className="rounded-2xl bg-cover"
                >
                    <div className="card flex h-full flex-col items-center justify-center rounded-2xl border-2 border-black bg-gray-700 bg-opacity-60 px-4 py-20 text-white shadow-xl backdrop-filter hover:backdrop-blur">
                        <h1 className="text-2xl font-bold">Blog</h1>
                        <p className="py-6 text-center">
                            Your questions are answered by qualified imams.
                        </p>
                        <a
                            className="bg-primary rounded-lg px-4 py-2 text-white"
                            href="/blog"
                        >
                            View Blogs
                        </a>
                    </div>
                </div>
                <div
                    style={{ backgroundImage: `url(${FreePDF})` }}
                    className="rounded-2xl bg-cover bg-center md:col-span-2"
                >
                    <div className="card flex h-full flex-col items-center justify-center rounded-2xl bg-gray-700 bg-opacity-60 px-4 py-20 text-white shadow-xl backdrop-filter hover:backdrop-blur ">
                        <h1 className="text-2xl font-bold">
                            Download Free PDF
                        </h1>
                        <p className="py-6 text-center">
                            Your questions are answered by qualified imams.
                        </p>
                        <a
                            className="bg-primary rounded-lg px-4 py-2 text-white"
                            href="/free-pdf"
                        >
                            Browse PDFs
                        </a>
                    </div>
                </div>
            </div>

            <div
                style={{
                    backgroundImage: `url(${dark_quran})`,
                }}
                className="card relative mb-8 grid w-full grid-cols-1 overflow-hidden rounded-xl bg-[#120F10] bg-cover bg-right px-16 py-24 shadow-xl lg:grid-cols-2"
            >
                <div className="z-10 text-white">
                    <h1 className="mb-4 text-3xl">Contact us</h1>
                    <p className="mb-8 lg:my-8">
                        Have any question? Want to join us? Contact us?
                    </p>
                    <a
                        className="text-primary rounded-lg bg-white px-4 py-2"
                        href="/contact"
                    >
                        Contact
                    </a>
                </div>
            </div>
        </div>
    );
}
