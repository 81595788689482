import { Fragment, React, useEffect, useState } from "react";
import { SelectorIcon } from "@heroicons/react/solid";
import { Listbox, Dialog, Transition } from "@headlessui/react";
import SetOG from "../Extras/SetOG";
import zakaah from "../../assets/images/zakaah.jpg";
const currency = [
    {
        id: 1,
        label: "United States Dollar",
        value: "USD",
        rate: 1,
    },
    {
        id: 2,
        label: "European Euro",
        value: "EUR",
        rate: 0.91,
    },
    {
        id: 3,
        label: "British Pound",
        value: "GBP",
        rate: 0.76,
    },
    {
        id: 4,
        label: "Canadian Dollar",
        value: "CAD",
        rate: 1.25,
    },
    {
        id: 5,
        label: "Australian Dollar",
        value: "AUD",
        rate: 1.33,
    },
    {
        id: 6,
        label: "Bangladeshi Taka",
        value: "BDT",
        rate: 86.33,
    },
];
export default function ZakaahCalculator() {
    const [cash, setCash] = useState(0);
    const [bankBalance, setBankBalance] = useState(0);
    const [shares, setShares] = useState(0);
    const [stocks, setStocks] = useState(0);
    const [products, setProducts] = useState(0);
    const [metals, setMetals] = useState(0);
    const [property, setProperty] = useState(0);
    const [liquid, setLiquid] = useState(0);
    const [totalDebt, setTotalDebt] = useState(null);
    const [totalInstallment, setTotalInstallment] = useState(null);
    const [zakaahAbleAsset, setZakaahAbleAsset] = useState(null);

    const [eligible, setEligible] = useState(null);
    const [totalZakaah, setTotalZakaah] = useState(null);
    const [currencySelected, setCurrencySelected] = useState(currency[0]);
    const [LocalCurrencyNisaab, setLocalCurrencyNisaab] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }
    useEffect(() => {
        const nisaab = 5300;
        let za =
            Number(cash) +
            Number(bankBalance) +
            Number(shares) +
            Number(stocks) +
            Number(products) +
            Number(metals) +
            Number(property) +
            Number(liquid) -
            Number(totalDebt) -
            Number(totalInstallment);
        setZakaahAbleAsset(za);
        console.log("Zakaah able asset: ", zakaahAbleAsset);
        setLocalCurrencyNisaab(Number(nisaab) * Number(currencySelected.rate));
        setEligible(
            za >= Number(nisaab) * Number(currencySelected.rate) ? true : false
        );
        console.log(eligible);

        //calculating zakaah in user selected currency
        setTotalZakaah(parseFloat(zakaahAbleAsset * (2.5 / 100)).toFixed(3));
        console.log(totalZakaah);
    }, [
        cash,
        bankBalance,
        shares,
        stocks,
        products,
        metals,
        property,
        liquid,
        totalDebt,
        totalInstallment,
        currencySelected,
        eligible,
        totalZakaah,
        zakaahAbleAsset,
    ]);
    return (
        <div>
            <SetOG title="Zakaah Calculator" />
            <div
                style={{ backgroundImage: `url(${zakaah})` }}
                className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-lg"
            >
                <div className="z-10 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-40">
                    <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold text-white">
                        Zakaah Calculator
                    </h1>
                </div>
            </div>
            <div className="mb-8 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5">
                <div className="col-span-1 mb-8 lg:m-8  xl:col-span-3 xl:mt-16">
                    <h1 className="hidden text-3xl font-bold lg:block xl:text-6xl">
                        Zakaah Calculator
                    </h1>
                    <h3 className="my-4 mx-2 text-lg">
                        If you have any questions, please feel free to{" "}
                        <a href="/contact">contact us.</a>
                    </h3>
                    <div className="card mt-4 w-full rounded-2xl bg-white p-6 shadow-2xl">
                        <div className="prose">
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <em>
                                                <strong>Allah</strong>
                                            </em>{" "}
                                            says,
                                        </span>
                                    </span>
                                </span>
                            </p>

                            <p>
                                <span>
                                    <span>
                                        <span>وَمَا</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>آَتَيْتُمْ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>مِنْ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>رِبًا</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>لِي</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>أَمْوَالِ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>النَّاسِ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>فَلَا</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>يَرْبُو</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>عِنْدَ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>اللَّهِ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>وَمَا</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>َْتَيْتُمْ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>مِنْ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>زَكَاةٍ</span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span> </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>تُرِدُونَ</span>
                                    </span>
                                </span>
                            </p>

                            <p>
                                <span>
                                    <span>
                                        <span>
                                            ( interpretation of the
                                            meaning)&#39;The interest-based
                                            deals you make to increase
                                            people&#39;s wealth aren&#39;t
                                            really profitable in the sight of
                                            Allah. On the other hand, whatever
                                            zakat you pay in the hope of gaining
                                            Allah&#39;s pleasure, (it increases)
                                            so the zakat payers double the
                                            profit. &#39; (Surah Room 39).
                                        </span>
                                    </span>
                                </span>
                            </p>

                            <p>
                                <br />
                                &nbsp;
                            </p>

                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>What&#39;s Zakat:</strong>
                                        </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>
                                            {" "}
                                            The word &quot;zakat&quot; means
                                            &quot;to purify, sanctify, or
                                            increase.&quot; In the language of
                                            Shariah, it means handing over a
                                            fixed asset to its rightful
                                            descendant on a definite condition.
                                            In other words, if a Muslim owns the
                                            amount of wealth prescribed by Allah
                                            (nisab) and he has had it for one
                                            year, then delivering the prescribed
                                            amount to the rightful owner is
                                            called zakat.
                                        </span>
                                    </span>
                                </span>
                            </p>

                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                Zakat is obligatory on whom:
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>
                                            {" "}
                                            According to Islamic Sharia, it is
                                            obligatory to pay Zakat to every
                                            Muslim man and woman among whom the
                                            following conditions are found:1.
                                            Muslim 2. independent, 3. To be
                                            wise, 4. maturing into an adult, 5.
                                            Nisab amount of wealth, 6. Having
                                            full ownership, 6. A full year of
                                            ownership of the assets has elapsed.
                                        </span>
                                    </span>
                                </span>
                            </p>

                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>When to pay Zakat:</strong>
                                        </span>
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        <span>
                                            {" "}
                                            It is obligatory for all Muslim men
                                            and women who have a nisab amount of
                                            wealth to pay Zakat. It is
                                            obligatory for a person to pay zakat
                                            in the previous year if one full
                                            year has elapsed since the moon
                                            after the owner of the nisab amount
                                            of wealth. Of course, if a person
                                            owns the nisab of zakat as well as
                                            is in debt, then zakat will be
                                            obligatory on him if he owns the
                                            amount of nisab even after deducting
                                            the debt. If a person spends money
                                            and property without paying the
                                            zakat, then he has to pay the
                                            previous zakat as well.
                                        </span>
                                    </span>
                                </span>
                            </p>

                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
                <div className="col-span-1 xl:col-span-2">
                    <div className="w-full rounded-xl bg-white p-8 shadow-2xl">
                        <div className="col-span-2 mb-4">
                            <Listbox
                                value={currencySelected}
                                onChange={setCurrencySelected}
                            >
                                <div className="relative mt-0">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg border border-gray-300 bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                        <span className="block truncate">
                                            {!currencySelected.label
                                                ? "Select Currency"
                                                : currencySelected.label}
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <SelectorIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {currency.map(
                                                (person, personIdx) => (
                                                    <Listbox.Option
                                                        key={personIdx}
                                                        className={({
                                                            active,
                                                        }) =>
                                                            `relative cursor-default select-none p-2 ${
                                                                active
                                                                    ? "bg-amber-100 text-amber-900"
                                                                    : "text-gray-900"
                                                            }`
                                                        }
                                                        value={person}
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={`block truncate ${
                                                                        selected
                                                                            ? "font-medium"
                                                                            : "font-normal"
                                                                    }`}
                                                                >
                                                                    {
                                                                        person.label
                                                                    }
                                                                </span>
                                                                {selected ? (
                                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                )
                                            )}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="totalCash"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Total Cash in Home
                            </label>
                            <input
                                onChange={(e) => {
                                    setCash(e.target.value);
                                }}
                                type="totalCash"
                                id="totalCash"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="totalBankBalance"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Total Balance held in Bank(Including DPS, FDR)
                            </label>
                            <input
                                onChange={(e) => setBankBalance(e.target.value)}
                                type="totalBankBalance"
                                id="totalBankBalance"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="totalShare"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Resell Value of Primary Shares
                            </label>
                            <input
                                onChange={(e) => setShares(e.target.value)}
                                type="totalShare"
                                id="totalShare"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="totalStocks"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Buying price of company stocks
                            </label>
                            <input
                                onChange={(e) => setStocks(e.target.value)}
                                type="totalStocks"
                                id="totalStocks"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="totalProduct"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Buying value of products/raw material at
                                shop/factory (Bought for sell)
                            </label>
                            <input
                                onChange={(e) => setProducts(e.target.value)}
                                type="totalProduct"
                                id="totalProduct"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="totalMetal"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Current Market Value of Gold, Silver etc.
                                metals.
                            </label>
                            <input
                                onChange={(e) => setMetals(e.target.value)}
                                type="totalMetal"
                                id="totalMetal"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="totalProperty"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Value of properties bought for reselling.
                            </label>
                            <input
                                onChange={(e) => setProperty(e.target.value)}
                                type="totalProperty"
                                id="totalProperty"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="totalLiquid"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Liquid assets (e.g. bond, equity)
                            </label>
                            <input
                                onChange={(e) => setLiquid(e.target.value)}
                                type="totalLiquid"
                                id="totalLiquid"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>

                        <div className="mb-6">
                            <label
                                htmlFor="payableDebt"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Payable Debt (If any)
                            </label>
                            <input
                                onChange={(e) => setTotalDebt(e.target.value)}
                                type="payableDebt"
                                id="payableDebt"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder={
                                    "Amount in " + currencySelected.label
                                }
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="payableInstallment"
                                className="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                            >
                                Payable Total Installments (If any)
                            </label>
                            <input
                                onChange={(e) =>
                                    setTotalInstallment(e.target.value)
                                }
                                type="payableInstallment"
                                id="payableInstallment"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder="Amount in digits."
                            />
                        </div>
                        <button
                            onClick={openModal}
                            className="bg-primary hover:bg-secondary w-full rounded-lg py-3 font-bold text-white"
                        >
                            Calculate Zakaah
                        </button>
                    </div>
                </div>
            </div>
            <div
                id="resultDiv"
                className="mb-8 w-full rounded-xl bg-white p-8 shadow-2xl"
            >
                <div className="text-primary my-8">
                    <p className="mt-4 text-sm text-gray-500">
                        **To get accurate amount of Nisaab, please refer to your
                        local market and know the current price of gold.
                        <br />
                        Nisaab is the price of 87.48 grams of gold in your local
                        market.
                    </p>
                </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen bg-black bg-opacity-80 px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className={
                                    "my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl border bg-white p-6 text-center align-middle shadow-xl transition-all " +
                                    (eligible === true
                                        ? "border-green-400"
                                        : "border-red-400")
                                }
                            >
                                <Dialog.Title className="mb-4 text-center text-xl font-bold text-gray-700">
                                    You're{" "}
                                    {!eligible && (
                                        <span className="text-red-700">
                                            not
                                        </span>
                                    )}{" "}
                                    eligible for zakaah
                                </Dialog.Title>
                                <div>
                                    <h3 className="mb-1">
                                        Your Zakaah-able asset:{" "}
                                        {zakaahAbleAsset}{" "}
                                        {currencySelected.value}
                                    </h3>
                                    <h3 className="mb-1">
                                        Nisaab is {LocalCurrencyNisaab}{" "}
                                        {currencySelected.value}
                                    </h3>
                                    <h3>
                                        Your Zakaah amount is {totalZakaah}{" "}
                                        {currencySelected.value}
                                    </h3>
                                </div>
                                <p className="mt-4 text-sm text-gray-500">
                                    To get accurate amount of Nisaab, please
                                    refer to your local market and know the
                                    current price of gold.
                                    <br />
                                    Nisaab is the price of 87.48 grams of gold
                                    in your local market.
                                </p>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        Exit
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
