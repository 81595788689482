import { BookmarkAltIcon } from "@heroicons/react/outline";
import SetOG from "../Extras/SetOG";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
const square_ad = `<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=290&l=ur1&category=primediscounted&banner=1H1PGDP30CXCR24ATP02&f=ifr&linkID=56848d5db79e395e5322f175f440918d&t=illiyn-20&tracking_id=illiyn-20" width="320" height="250" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0" sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>`;
export default function SingleMustReadBook() {
    let slug = useParams().slug;
    const [book, setBook] = useState([]);
    const [iframes, setIframes] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;
    useEffect(() => {
        fetch(api_url + "/api/must-read-books/" + slug)
            .then((res) => res.json())
            .then(
                (result) => {
                    setBook(result.book);
                    setIframes(result.recent);
                },
                (error) => {}
            );
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=089efc14-83b4-429d-9faa-50fc6c5ebd79`;
        const suggestedAd = document.querySelector(
            "#amzn-assoc-ad-089efc14-83b4-429d-9faa-50fc6c5ebd79"
        );
        suggestedAd.appendChild(script);
    }, [api_url, slug]);
    return (
        <>
            <div className="relative mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <SetOG
                    title={book.title}
                    description="Must Read Books on illiyn"
                />
                <div className="relative mt-8 flex flex-col items-center justify-center overflow-hidden rounded-xl py-32">
                    <div className="absolute z-0 h-full w-full bg-slate-700 opacity-100"></div>
                    <div className="z-10 text-center text-white">
                        <h1 className="my-4 text-2xl font-bold md:text-3xl lg:text-5xl">
                            {book.title}
                        </h1>
                        <p>Posted On: {book.date}</p>
                        <p className="mt-3">Written By: {book.author}</p>
                    </div>
                </div>
                <div className="grid w-full grid-cols-1 lg:grid-cols-3">
                    <div className="col-span-2">
                        <article>
                            <div>
                                <a href={book.buy_link} target="_blank">
                                    <img
                                        className="float-left m-5 w-1/3"
                                        border="0"
                                        src={book.image}
                                    />
                                </a>
                            </div>
                            <div className="prose m-0 w-full p-3 text-justify">
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: book.content,
                                    }}
                                />
                                <div
                                    className="w-full"
                                    id="amzn-assoc-ad-089efc14-83b4-429d-9faa-50fc6c5ebd79"
                                ></div>
                            </div>
                        </article>

                        <div className="card my-12 flex-col items-center justify-center rounded-lg bg-white p-4 shadow-lg lg:flex">
                            <h2 className="py-4 text-center text-2xl font-bold">
                                Buy Some Amazing Books
                            </h2>
                            <div className="flex w-full justify-around">
                                {iframes.map((iframe) => (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: iframe.iframe,
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="ml-5">
                        <div className="card top-5 my-8 flex flex-col items-center justify-center rounded-lg bg-white p-4 shadow-lg">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: book.iframe,
                                }}
                            />
                        </div>
                        <div
                            className="sticky top-5"
                            dangerouslySetInnerHTML={{ __html: square_ad }}
                        ></div>
                    </div>
                </div>
            </div>
        </>
    );
}
