import React from "react";
import TitleHeader from "../Extras/TitleHeader";
import SetOG from "../Extras/SetOG";
const text = `<p>Our aim is to attain the satisfaction of <em><strong>Allah</strong></em> Ta&#39;ala, emancipation in the hereafter, and the development of humanity in this world, to make Mubalalig and Nayeb Rasool who are God-loving, and worthy of religion and reconciliation. Achieving the welfare of the world and the Hereafter by acting according to the knowledge acquired above all.&nbsp;</p>

<p><br />
There are two lights inside man, one is the light of his heart which is illuminated by the knowledge of Qur&#39;an and Hadith and the other is the light of intellect which shines through the accession of worldly knowledge which he uses to live in this world. However, then man will be able to know Allah only through the knowledge of the world If the two lights of man can be lit here. Our education system is to illuminate the inner heart and inner light of every student so that the students can attain both this world and the hereafter with the knowledge they&#39;ve acquired. To that end, our organization is working to educate helpless, destitute, and orphaned children in religious education.</p>

<p><br />
The Prophet ( peace and blessings of Allah be upon him) said,</p>

<blockquote>
<p>The best of you is the one who learns and teaches the Qur&#39;an. (Bukhari 5026)</p>
</blockquote>

<p><br />
&nbsp;We have no source of income or sponsors. And all our activities are conducted with the money donated by you. The money given by you is spent according to the complete Islamic Shariah.<br />
&nbsp;</p>
`;
export default function About() {
    return (
        <div>
            <SetOG title="About illiyn" />
            <TitleHeader title="About Us" />
            <div className="prose mx-auto mb-8">
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    );
}
