import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { GoBrowser } from "react-icons/go";
import scholar_bg from "../../assets/images/scholars.jpg";
import SetOG from "../Extras/SetOG";
export default function Scholars() {
    const [scholars, setScholars] = useState([]);
    let [isOpen, setIsOpen] = useState(false);
    let [name, setName] = useState("");
    let [country, setCountry] = useState("");
    let [facebook, setFacebook] = useState("");
    let [twitter, setTwitter] = useState("");
    let [website, setWebsite] = useState("");
    let [youtube, setYoutube] = useState("");

    function closeModal() {
        setIsOpen(false);
    }

    function openModal(n, c, f, t, w, y) {
        setName(n);
        setCountry(c);
        setFacebook(f);
        setTwitter(t);
        setWebsite(w);
        setYoutube(y);
        setIsOpen(true);
    }

    useEffect(() => {
        const api_url = process.env.REACT_APP_API_URL + "/api/scholars/";

        fetch(api_url)
            .then((res) => res.json())
            .then((result) => {
                setScholars(result);
                console.log(result);
            });
    }, []);

    return (
        <div className="relative ">
            <SetOG title="World Scholar Activities" />
            <div
                style={{ backgroundImage: `url(${scholar_bg})` }}
                className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-lg"
            >
                <div className="z-10 flex h-full w-full flex-col items-center justify-center bg-white bg-opacity-30">
                    <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold">
                        World Scholar Activities
                    </h1>
                </div>
            </div>
            <div className="mb-8 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                        >
                                            <span className="ml-4">Name</span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                                        >
                                            Website
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                                        >
                                            Facebook
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                                        >
                                            Youtube
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                                        >
                                            Twitter
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {scholars.map((scholar) => (
                                        <tr
                                            key={scholar.name}
                                            className="hover:bg-gray-100"
                                        >
                                            <td
                                                className="whitespace-nowrap px-6 py-4 hover:cursor-pointer"
                                                onClick={() =>
                                                    openModal(
                                                        scholar.name,
                                                        scholar.country,
                                                        scholar.facebook,
                                                        scholar.twitter,
                                                        scholar.website,
                                                        scholar.youtube
                                                    )
                                                }
                                            >
                                                <div className="flex items-center">
                                                    <div className="ml-4">
                                                        <div className="text-sm font-bold text-gray-900">
                                                            {scholar.name}
                                                        </div>
                                                        <div className="text-sm font-bold text-gray-500">
                                                            {scholar.country}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-6 text-center">
                                                {scholar.website === "" ? (
                                                    <span className="text-md  flex w-full items-center justify-center rounded-xl py-4 text-primary">
                                                        N/A
                                                    </span>
                                                ) : (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="flex items-center justify-center rounded-xl p-4 text-2xl text-primary hover:bg-primary hover:text-white"
                                                        href={scholar.website}
                                                    >
                                                        <GoBrowser />
                                                    </a>
                                                )}
                                            </td>
                                            <td className="whitespace-nowrap px-6 text-center">
                                                {scholar.facebook === "" ? (
                                                    <span className="text-md  flex w-full items-center justify-center rounded-xl py-4 text-primary">
                                                        N/A
                                                    </span>
                                                ) : (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="flex  w-full items-center justify-center rounded-xl py-4 text-2xl text-primary hover:bg-primary hover:text-white"
                                                        href={scholar.facebook}
                                                    >
                                                        <BsFacebook />
                                                    </a>
                                                )}
                                            </td>
                                            <td className="whitespace-nowrap px-6 text-center">
                                                {scholar.youtube === "" ? (
                                                    <span className="text-md  flex w-full items-center justify-center rounded-xl py-4 text-primary">
                                                        N/A
                                                    </span>
                                                ) : (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="flex  w-full items-center justify-center rounded-xl py-4 text-2xl text-primary hover:bg-primary hover:text-white"
                                                        href={scholar.youtube}
                                                    >
                                                        <BsYoutube />
                                                    </a>
                                                )}
                                            </td>
                                            <td className="whitespace-nowrap px-6 text-center">
                                                {scholar.twitter === "" ? (
                                                    <span className="text-md  flex w-full items-center justify-center rounded-xl py-4 text-primary">
                                                        N/A
                                                    </span>
                                                ) : (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="flex  w-full items-center justify-center rounded-xl py-4 text-2xl text-primary hover:bg-primary hover:text-white"
                                                        href={scholar.twitter}
                                                    >
                                                        <BsTwitter />
                                                    </a>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen bg-black bg-opacity-80 px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h2"
                                    className="text-center text-xl font-bold leading-6 text-gray-900"
                                >
                                    {name}
                                </Dialog.Title>
                                <p className="mt-4 text-center">{country}</p>
                                <div className="my-8 flex justify-around text-primary">
                                    {facebook === "" ? (
                                        <span className="text-md flex items-center justify-center p-3">
                                            N/A
                                        </span>
                                    ) : (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={facebook}
                                            className="rounded-lg p-3 text-3xl hover:bg-primary hover:text-white"
                                        >
                                            <BsFacebook />
                                        </a>
                                    )}
                                    {website === "" ? (
                                        <span className="text-md flex items-center justify-center p-3">
                                            N/A
                                        </span>
                                    ) : (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={website}
                                            className="rounded-lg p-3 text-3xl hover:bg-primary hover:text-white"
                                        >
                                            <GoBrowser />
                                        </a>
                                    )}
                                    {youtube === "" ? (
                                        <span className="text-md flex items-center justify-center p-3">
                                            N/A
                                        </span>
                                    ) : (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={youtube}
                                            className="rounded-lg p-3 text-3xl hover:bg-primary hover:text-white"
                                        >
                                            <BsYoutube />
                                        </a>
                                    )}
                                    {twitter === "" ? (
                                        <span className="text-md flex items-center justify-center p-3">
                                            N/A
                                        </span>
                                    ) : (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={twitter}
                                            className="rounded-lg p-3 text-3xl hover:bg-primary hover:text-white"
                                        >
                                            <BsTwitter />
                                        </a>
                                    )}
                                </div>

                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        Exit
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}
