import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import loader from "./../../assets/extra/loader.svg";
import AllBlogMustReadBook from "./../../assets/images/AllBlogMustReadBook.webp";
import SetOG from "../Extras/SetOG";
export default function BlogCategory() {
    const blog_image_url = process.env.REACT_APP_API_URL;
    var { cat = "all" } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [category, setCategory] = useState("");
    const [blog, setBlog] = useState([]);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        const api_url = process.env.REACT_APP_API_URL + "/api/blog/" + cat;
        fetch(api_url)
            .then((res) => res.json())
            .then(
                (result) => {
                    setBlog(result.blogs);
                    setCategory(result.category);
                    setIsLoaded(true);
                    setIsError(false);
                    if (result.blogs.length === 0) {
                        setIsError(true);
                        setError("No Blogs Found");
                    }
                },
                (error) => {
                    setIsError(true);
                    setError(error);
                }
            );
    }, [cat]);
    return (
        <div className="relative overflow-hidden">
            <SetOG title={category + " Blogs"} description="Illiyin's Blogs" />
            <div
                style={{ backgroundImage: `url(${AllBlogMustReadBook})` }}
                className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-xl"
            >
                <div className="z-10 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-50">
                    <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold text-white">
                        Blog: {category}
                    </h1>
                </div>
            </div>
            {isLoaded ? (
                <>
                    <div className="mb-8 grid w-full grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
                        {blog.map((item) => (
                            <a href={"/blog/" + item.slug} className="">
                                <div className="group my-3 flex h-full w-full items-start overflow-hidden rounded-xl border-2 border-gray-300 p-2 hover:bg-gray-100">
                                    <div className="h-auto w-full">
                                        <div className="overflow-hidden rounded-lg">
                                            <img
                                                src={
                                                    blog_image_url + item.image
                                                }
                                                className="aspect-video transition-all duration-500 group-hover:scale-125"
                                                alt={item.title}
                                            />
                                        </div>
                                        <div className="px-5 pt-5">
                                            <span className="bg-primary rounded-md p-1 px-3 text-[0.7em] text-white">
                                                {item.category}
                                            </span>
                                            <h1 className="text-primary mt-4 mb-2 text-lg font-bold group-hover:text-orange-600">
                                                {item.title}
                                            </h1>
                                            <div className="flex justify-between text-sm">
                                                {item.date}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </>
            ) : (
                <div className="mb-8 flex w-full flex-col items-center justify-center">
                    <img src={loader} alt="loader" className="my-8 h-12" />
                    <h1 className="text-3xl font-bold">Loading...</h1>
                </div>
            )}

            {isError ? (
                <div className="w-full text-center">
                    <p className="text-lg">{error}</p>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
