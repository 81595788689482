/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
    MenuIcon,
    ViewGridIcon,
    XIcon,
    FireIcon,
    EmojiHappyIcon,
    ArrowCircleRightIcon,
    CheckCircleIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import logo from "./../assets/logo.svg";
const blogs = [
    {
        name: "Multitask Learning",
        description:
            "General blog about the Must Know Stuffs for both muslims and non-muslims",
        href: "/blog/multitask-learning",
        icon: CheckCircleIcon,
    },
    {
        name: "Legends of Islam",
        description: "Know about the legends of the history of Islam.",
        href: "/blog/legends-of-islam/",
        icon: FireIcon,
    },
    {
        name: "When Muhammad (ﷺ) Smiled",
        description:
            "Stories about our beloved prophet Muhammad Ibn Abdullah (ﷺ).",
        href: "/blog/when-muhammad-pbuh-smiled",
        icon: EmojiHappyIcon,
    },
    {
        name: "Mas'ala",
        description: "Mas'alas collected from various Alems",
        href: "/blog/mas'ala",
        icon: EmojiHappyIcon,
    },
];

const menuItems = [
    {
        name: "Scholars",
        href: "/scholars",
        icon: ArrowCircleRightIcon,
    },
    {
        name: "Shop",
        href: "/shop/",
        icon: ArrowCircleRightIcon,
    },
    {
        name: "Must Read books",
        href: "/must-read-books/",
        icon: ArrowCircleRightIcon,
    },
    {
        name: "Free PDF",
        href: "/free-pdf/",
        icon: ArrowCircleRightIcon,
    },
    {
        name: "Zakaah Calculator",
        href: "/zakaah-calculator/",
        icon: ArrowCircleRightIcon,
    },
    {
        name: "Ask an Imam",
        href: "/ask-an-imam/",
        icon: ArrowCircleRightIcon,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Example() {
    const [isShowing, setIsShowing] = useState(false);
    return (
        <Popover className="sticky top-0 z-20 bg-white bg-opacity-70 backdrop-blur backdrop-filter">
            <div className="mx-auto flex max-w-7xl flex-col px-4 sm:px-6">
                <div className="flex items-center justify-between border-b-2 border-blue-100 py-6 lg:justify-start lg:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a href="/" className="flex items-center">
                            <span className="sr-only">illiyn</span>
                            <img
                                className="h-8 w-auto sm:h-10"
                                src={logo}
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="-my-2 -mr-2 lg:hidden">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Popover.Group
                        as="nav"
                        className="hidden space-x-6 lg:flex"
                    >
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open
                                                ? "text-gray-700"
                                                : "text-black",
                                            "group rounded-m inline-flex items-center text-base font-medium hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        )}
                                        onMouseEnter={() => setIsShowing(true)}
                                        onMouseLeave={() => setIsShowing(false)}
                                    >
                                        <span>Blog</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open
                                                    ? "text-gray-600"
                                                    : "text-gray-400",
                                                "ml-2 h-5 w-5 group-hover:text-black"
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                        show={isShowing}
                                        onMouseEnter={() => setIsShowing(true)}
                                        onMouseLeave={() => setIsShowing(false)}
                                    >
                                        <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                    {blogs.map((item) => (
                                                        <a
                                                            key={item.name}
                                                            href={item.href}
                                                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                                        >
                                                            <item.icon
                                                                className="h-6 w-6 flex-shrink-0 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                            <div className="ml-4">
                                                                <p className="text-base font-bold text-gray-800">
                                                                    {item.name}
                                                                </p>
                                                                <p className="mt-1 text-sm text-gray-700">
                                                                    {
                                                                        item.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>

                        <a
                            href="/scholars"
                            className="text-base font-medium text-black hover:text-gray-700"
                        >
                            Scholars
                        </a>
                        <a
                            href="/shop/"
                            className="text-base font-medium text-black hover:text-gray-700"
                        >
                            Shop
                        </a>
                        <a
                            href="/must-read-books/"
                            className="text-base font-medium text-black hover:text-gray-700"
                        >
                            Must Read books
                        </a>
                        <a
                            href="/free-pdf/"
                            className="text-base font-medium text-black hover:text-gray-700"
                        >
                            Free PDF
                        </a>
                        <a
                            href="/zakaah-calculator/"
                            className="text-base font-medium text-black hover:text-gray-700"
                        >
                            Zakaah Calculator
                        </a>
                        <a
                            href="/ask-an-imam/"
                            className="text-base font-medium text-black hover:text-gray-700"
                        >
                            Ask an Imam
                        </a>
                        <a
                            href="/donate"
                            className="whitespace-nowrap text-base font-medium text-black hover:text-gray-700"
                        >
                            Donate❤️
                        </a>
                    </Popover.Group>
                    <div className="hidden items-center justify-end lg:flex lg:w-0 lg:flex-1">
                        {!localStorage.getItem("token") ? (
                            <a
                                href="/login"
                                className="bg-primary hover:bg-secondary ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm"
                            >
                                Sign In
                            </a>
                        ) : (
                            <a
                                href="/logout"
                                className="bg-primary hover:bg-secondary ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm"
                            >
                                Logout
                            </a>
                        )}
                    </div>
                </div>
                <div></div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    focus
                    className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden"
                >
                    <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="px-5 pt-5 pb-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src={logo}
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">
                                            Close menu
                                        </span>
                                        <XIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    <p className="m-3 -ml-3 -mb-6 flex items-center rounded-md p-3 text-lg font-bold">
                                        <ViewGridIcon
                                            className="h-6 w-6 flex-shrink-0 text-indigo-600"
                                            aria-hidden="true"
                                        />{" "}
                                        <span className="ml-3">Blog</span>
                                    </p>
                                    {blogs.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-m-3 ml-0 flex items-center rounded-md p-3 hover:bg-gray-50"
                                        >
                                            <item.icon
                                                className="h-6 w-6 flex-shrink-0 text-indigo-600"
                                                aria-hidden="true"
                                            />
                                            <span className="ml-3 text-base font-medium text-gray-700">
                                                {item.name}
                                            </span>
                                        </a>
                                    ))}
                                </nav>
                                <nav className="grid gap-y-8">
                                    <p className="m-3 -ml-3 -mb-6 flex items-center rounded-md p-3 text-lg font-bold">
                                        <ViewGridIcon
                                            className="h-6 w-6 flex-shrink-0 text-indigo-600"
                                            aria-hidden="true"
                                        />{" "}
                                        <span className="ml-3">More</span>
                                    </p>
                                    {menuItems.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-m-3 ml-0 flex items-center rounded-md p-3 hover:bg-gray-50"
                                        >
                                            <item.icon
                                                className="h-6 w-6 flex-shrink-0 text-indigo-600"
                                                aria-hidden="true"
                                            />
                                            <span className="ml-3 text-base font-medium text-gray-700">
                                                {item.name}
                                            </span>
                                        </a>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        <div className="space-y-6 py-6 px-5">
                            <div>
                                <a
                                    href="/donate"
                                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                                >
                                    Donate❤️
                                </a>
                                <p className="mt-6 text-center text-base font-medium text-black">
                                    Existing customer?{" "}
                                    <a
                                        href="/login"
                                        className="text-indigo-600 hover:text-indigo-500"
                                    >
                                        Sign in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
