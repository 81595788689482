import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./templates/Header.jsx";
import Footer from "./templates/Footer.jsx";
import Home from "./pages/Home.jsx";
import Login from "./pages/Dashboard/Login.jsx";
import SignUp from "./pages/Dashboard/SignUp.jsx";
import DashBoard from "./pages/Dashboard/DashBoard.jsx";
import SingleBlog from "./pages/Blog/SingleBlog.jsx";
import BlogCategory from "./pages/Blog/BlogCategory.jsx";
import Scholars from "./pages/Extras/Scholars.jsx";
import FreePDF from "./pages/Extras/FreePDF.jsx";
import NotFound from "./templates/NotFound.jsx";
import MustReadBooks from "./pages/MustReadBooks/MustReadBooks.jsx";
import SingleMustReadBook from "./pages/MustReadBooks/SingleMustReadBook.jsx";
import Donate from "./pages/Extras/Donate.jsx";
import ZakaahCalculator from "./pages/Extras/ZakaahCalculator.jsx";
import AskAnImam from "./pages/AskAnImam/AskAnImam.jsx";
import SingleAskAnImam from "./pages/AskAnImam/SingleAskAnImam.jsx";
import Shop from "./pages/Shop/Shop.jsx";
import ShopDetail from "./pages/Shop/ShopDetail.jsx";
import * as Company from "./pages/Company";
import Logout from "./pages/Dashboard/Logout.jsx";

const App = () => (
    <Router>
        <div className="font-poppins -z-10 m-0 flex min-h-screen flex-col bg-orange-50 bg-opacity-50">
            <Header />
            <div className="mb-auto min-h-full w-full">
                <div className="min-h-2xl relative mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/blog" element={<BlogCategory />} />
                        <Route path="/blog/:cat" element={<BlogCategory />} />
                        <Route
                            path="/blog/:cat/:slug"
                            element={<SingleBlog />}
                        />
                        <Route path="/scholars" element={<Scholars />} />
                        <Route path="/free-pdf" element={<FreePDF />} />
                        <Route
                            path="/must-read-books"
                            element={<MustReadBooks />}
                        />
                        <Route
                            path="/must-read-books/:slug"
                            element={<SingleMustReadBook />}
                        />
                        <Route path="/shop" element={<Shop />} />
                        <Route path="/shop/:cat" element={<ShopDetail />} />
                        <Route path="/donate" element={<Donate />} />
                        <Route
                            path="/zakaah-calculator"
                            element={<ZakaahCalculator />}
                        />
                        <Route path="/ask-an-imam" element={<AskAnImam />} />
                        <Route
                            path="/ask-an-imam/:id"
                            element={<SingleAskAnImam />}
                        />
                        <Route path="/about" element={<Company.About />} />
                        <Route path="/contact" element={<Company.Contact />} />
                        <Route path="/privacy" element={<Company.Privacy />} />
                        <Route path="/terms" element={<Company.Terms />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/dashboard" element={<DashBoard />} />

                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </div>
            <Footer />
        </div>
    </Router>
);
export default App;
