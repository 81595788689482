import React from "react";
import logo from "./../assets/logo.svg";
const blogs = [
    {
        name: "Multitask Learning",
        description:
            "General blog about the Must Know Stuffs for both muslims and non-muslims",
        href: "/blog/multitask-learning",
    },
    {
        name: "Legends of Islam",
        description: "Know about the legends of the history of Islam.",
        href: "/blog/legends-of-islam/",
    },
    {
        name: "When Muhammad (ﷺ) Smiled",
        description:
            "Stories about our beloved prophet Muhammad Ibn Abdullah (ﷺ).",
        href: "/blog/when-muhammad-pbuh-smiled",
    },
    {
        name: "Mas'ala",
        description: "Mas'alas collected from various Alems",
        href: "/blog/mas'ala",
    },
];
export default function Footer() {
    return (
        <div class="z-10 bg-black px-4 text-white">
            <footer class="mx-auto max-w-7xl px-4 py-12">
                <div class="mb-3 grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-11 lg:gap-20">
                    <div class="col-span-3">
                        <a
                            href="/"
                            title="illiyn Home Page"
                            class="flex items-center text-2xl font-bold"
                        >
                            <img src={logo} alt="logo" className="h-12" />
                        </a>
                    </div>
                    <nav class="col-span-1 md:col-span-1 lg:col-span-2">
                        <p class="mb-3 text-xs font-semibold uppercase tracking-wider text-white">
                            Blog
                        </p>
                        {blogs.map((item) => (
                            <a
                                key={item.href}
                                class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                                href={item.href}
                            >
                                {item.name}
                            </a>
                        ))}
                    </nav>
                    <nav class="col-span-2 md:col-span-1 lg:col-span-2">
                        <p class="mb-3 text-xs font-semibold uppercase tracking-wider text-white">
                            Support
                        </p>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="#"
                        >
                            Chat
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="#"
                        >
                            {" "}
                            Email Support{" "}
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="#"
                        >
                            GDPR
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="#"
                        >
                            {" "}
                            Help{" "}
                        </a>
                    </nav>
                    <nav class="col-span-1 md:col-span-1 lg:col-span-2">
                        <p class="mb-3 text-xs font-semibold uppercase tracking-wider text-white">
                            Resources
                        </p>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="#"
                        >
                            Blog
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="#"
                        >
                            {" "}
                            Twitter{" "}
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="#"
                        >
                            Alternatives
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="#"
                        >
                            Why feature vote?
                        </a>
                    </nav>
                    <nav class="col-span-1 md:col-span-1 lg:col-span-2">
                        <p class="mb-3 text-xs font-semibold uppercase tracking-wider text-white">
                            Company
                        </p>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="/about"
                        >
                            About Us
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="/privacy"
                        >
                            Privacy
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="/terms"
                        >
                            Terms
                        </a>
                        <a
                            class="mb-3 flex text-sm font-medium text-gray-300 transition hover:text-gray-400 md:mb-2"
                            href="/contact"
                        >
                            Contact
                        </a>
                    </nav>
                </div>
                <div class="mt-10 flex flex-col items-start justify-between border-t border-gray-700 pt-10 md:flex-row md:items-center">
                    <a
                        href="/"
                        class="group mb-0 text-left text-xs text-gray-400 md:mb-0"
                    >
                        Copyright &copy; 2022{" "}
                        <span class="group-hover:text-orange-400">illiyn</span>.
                    </a>
                    <a
                        href="https://fardeen.es"
                        class="group mb-2 text-left text-xs text-gray-400 md:mb-0"
                    >
                        Developed and maintained by{" "}
                        <span class="group-hover:text-orange-400">
                            Fardeen Ehsan
                        </span>
                    </a>
                </div>
            </footer>
        </div>
    );
}
