import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SetOG from "../Extras/SetOG";
export default function SingleAskAnImam() {
    let param = useParams();
    let [blog, setBlog] = useState({});
    let [recent, setRecent] = useState([]);
    let [isLoaded, setIsLoaded] = useState(false);
    let [comments, Setcomments] = useState([]);
    let [blog_image, setImage] = useState();
    const api_url =
        process.env.REACT_APP_API_URL + "/api/ask-an-imam/" + param.id;
    useEffect(() => {
        function fetchAPI() {
            fetch(api_url)
                .then((res) => res.json())
                .then(
                    (result) => {
                        setBlog(result.question);
                        setRecent(result.recent);
                        Setcomments(result.comments);
                        setImage(
                            process.env.REACT_APP_API_URL + result.blog.image
                        );
                        setIsLoaded(true);
                        console.log(result);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
        fetchAPI();
    }, [api_url, blog_image]);

    const [author, setAuthor] = useState("");
    const [comment, setComment] = useState("");
    const blogID = blog.id;
    document.title = blog.question;
    const submitComment = (e) => {
        e.preventDefault();
        const newComment = { author, comment, blog: blogID };
        console.log(newComment);
        fetch(process.env.REACT_APP_API_URL + "/api/ask-an-imam/comment", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newComment),
        })
            .then((res) => res.json())
            .then((result) => {
                window.location.reload();
            });
    };

    return (
        <>
            <div className="relative mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <SetOG
                    title={blog.title + " : Ask an Imam"}
                    description="Ask our imam about your questions."
                />
                <div
                    style={{ backgroundImage: `url(${blog_image})` }}
                    className="relative mt-8 flex flex-col items-center justify-center overflow-hidden rounded-xl bg-cover bg-center bg-blend-overlay"
                >
                    <div className="z-10 h-full w-full bg-gray-800 bg-opacity-80 py-32 text-center text-white">
                        <h1 className="my-4 text-5xl font-bold">
                            {blog.title}
                        </h1>
                        <p>Posted On: {blog.date}</p>
                    </div>
                </div>
                <div className="mb-8 grid w-full grid-cols-1 lg:grid-cols-3">
                    <div className="col-span-2">
                        <article>
                            <div className="prose p-3">
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: blog.answer,
                                    }}
                                />
                            </div>
                        </article>
                        <div className="rounded-lg bg-white p-8 shadow-lg">
                            <h2 className="text-2xl font-bold">Comments</h2>
                            <div className="mt-4">
                                {comments.length === 0 ? (
                                    <p>
                                        No comments yet. Be the first one to
                                        comment.
                                    </p>
                                ) : (
                                    comments.map((item) => (
                                        <div className="mb-3 flex w-full items-start border-b-2 pb-3">
                                            <div className="h-12 w-12 overflow-hidden rounded-full">
                                                <img
                                                    src="https://avatars.dicebear.com/api/bottts/illiyn.svg"
                                                    alt="avatar"
                                                    className="h-full w-full object-cover"
                                                />
                                            </div>
                                            <div className="w-full pl-4">
                                                <p className="pb-1 font-bold">
                                                    {item.author}
                                                </p>
                                                <p className="text-justify">
                                                    {item.comment}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                            <div className="mt-8 w-full">
                                <form
                                    className="mx-auto w-full"
                                    onSubmit={submitComment}
                                >
                                    <div className="mx-auto w-3/4">
                                        <h2 className="mb-4 text-lg font-bold">
                                            Submit a comment
                                        </h2>
                                        <div className="mb-0 flex w-full flex-wrap">
                                            <label
                                                className="text-md mb-2 mr-4 block font-bold uppercase tracking-wide text-gray-700"
                                                htmlFor="grid-name"
                                            >
                                                Name
                                            </label>
                                            <input
                                                className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-3 px-4 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                                                id="grid-name"
                                                type="text"
                                                placeholder="John Doe"
                                                value={author}
                                                onChange={(e) =>
                                                    setAuthor(e.target.value)
                                                }
                                                required
                                            />
                                            <label
                                                className="text-md mb-2 mr-4 block font-bold uppercase tracking-wide text-gray-700"
                                                htmlFor="grid-comment"
                                            >
                                                Comment
                                            </label>
                                            <textarea
                                                className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-3 px-4 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                                                id="grid-comment"
                                                name="comment"
                                                type="text"
                                                placeholder="Your comment here"
                                                value={comment}
                                                onChange={(e) =>
                                                    setComment(e.target.value)
                                                }
                                                required
                                            />
                                        </div>

                                        <button
                                            className="focus:shadow-outline bg-primary hover:bg-secondary mb-2 rounded py-2 px-4 font-bold text-white focus:outline-none"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="ml-5">
                        <div className="sticky top-5 my-8">
                            <h2 className="text-lg font-bold">Latest Blogs</h2>
                            {recent.slice(0, 5).map((post) => (
                                <a href={"/blog/" + post.slug}>
                                    <div className="group my-4 flex items-center">
                                        <div className="w-1/4">
                                            <img
                                                src={
                                                    process.env
                                                        .REACT_APP_API_URL +
                                                    post.image
                                                }
                                                className="w-full"
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <p className="mx-4 font-semibold">
                                                {post.title}
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
