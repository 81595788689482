import { React, useState } from "react";
import TitleHeader from "../Extras/TitleHeader";
import { SiGmail } from "react-icons/si";
import SetOG from "../Extras/SetOG";
export default function Contact() {
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [query, setquery] = useState(null);
    const submitquery = (e) => {
        e.preventDefault();
        const newComment = { name, email, query };
        fetch(process.env.REACT_APP_API_URL + "/api/contact/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newComment),
        })
            .then((res) => res.json())
            .then((result) => {
                window.alert(result.message);
            })
            .catch((err) => {
                window.alert(err);
            });
    };
    return (
        <div>
            <div>
                <SetOG title="Contact us" />
                <TitleHeader title="Contact us" />
                <div className="mb-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
                    <div className="col-span-1 mb-8 xl:col-span-3 xl:mt-16">
                        <h1 className="text-3xl  font-bold xl:text-6xl">
                            Contact
                        </h1>
                        <h3 className="my-4 mx-2 text-lg">
                            If you have any questions, please feel free to
                            contact us.
                        </h3>
                        <p>
                            <a href="mailto:illiyn.contact@gmail.com">
                                <SiGmail className="text-primary hover:text-secondary m-2 inline-block text-2xl" />
                            </a>
                        </p>
                    </div>
                    <div className="col-span-1 xl:col-span-2">
                        <form
                            onSubmit={(e) => {
                                submitquery(e);
                            }}
                        >
                            <div className="w-full rounded-xl bg-white p-8 shadow-2xl">
                                <div class="mb-6">
                                    <label
                                        for="name"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Name
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        type="name"
                                        id="name"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="John Doe"
                                        required
                                    />
                                </div>
                                <div class="mb-6">
                                    <label
                                        for="email"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="mail@example.com"
                                        required
                                    />
                                </div>
                                <div class="mb-6">
                                    <label
                                        for="query"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Query
                                    </label>
                                    <textarea
                                        onChange={(e) =>
                                            setquery(e.target.value)
                                        }
                                        type="query"
                                        id="query"
                                        class="block h-24 w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="Type your query here"
                                        required
                                    />
                                </div>
                                <button
                                    className="bg-primary hover:bg-secondary w-full rounded-lg py-3 font-bold text-white"
                                    type="submit"
                                >
                                    Submit Query
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
