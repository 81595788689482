import React, { useState, useEffect } from "react";
import AllBlogMustReadBook from "./../../assets/images/AllBlogMustReadBook.webp";
import TitleHeader from "../Extras/TitleHeader";
import SetOG from "../Extras/SetOG";

export default function MustReadBooks() {
    const [books, setBooks] = useState([]);
    const [error, setError] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const api_url = process.env.REACT_APP_API_URL + "/api/must-read-books/";
        fetch(api_url)
            .then((res) => res.json())
            .then(
                (result) => {
                    setBooks(result);
                    setIsLoaded(true);
                    console.log(
                        process.env.REACT_APP_API_URL + result[0].image
                    );
                },
                (error) => {
                    setError(error);
                }
            );
    }, []);
    return (
        <div className="overflow-hidden">
            <SetOG title="Must Read Books" />
            <div
                style={{ backgroundImage: `url(${AllBlogMustReadBook})` }}
                className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-xl"
            >
                <div className="z-10 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-50">
                    <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold text-white">
                        Must Read Books
                    </h1>
                </div>
            </div>

            <div className="mb-8 grid w-full grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                {books.map((book) => (
                    <a href={"/must-read-books/" + book.slug}>
                        <div className="group books-center flex w-full py-3">
                            <div className="w-full">
                                <div className="relative overflow-hidden rounded-xl">
                                    <div className="absolute -z-10 flex h-full w-full items-center justify-center bg-gray-400">
                                        <p>Loading...</p>
                                    </div>
                                    <img
                                        src={book.image}
                                        className="z-0 aspect-[2/3] h-full w-full overflow-hidden rounded-lg transition-all duration-300 group-hover:scale-110"
                                        alt={book.title}
                                    />
                                </div>

                                <div className="p-2">
                                    <h1 className="text-primary my-2 text-lg group-hover:text-orange-600">
                                        {book.title}
                                    </h1>
                                    <div className="flex justify-between text-sm">
                                        <span>{book.author}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}
