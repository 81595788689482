import { React, Fragment, useState, useEffect } from "react";
import { SelectorIcon } from "@heroicons/react/solid";
import { Listbox, Transition } from "@headlessui/react";
import AskAnImam from "./../../assets/images/AskAnImam.webp";
import TitleHeader from "../Extras/TitleHeader";
import SetOG from "../Extras/SetOG";
export default function Donate({ location }) {
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [question, setQuestion] = useState(null);
    const [asked, setAsked] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const api_url = process.env.REACT_APP_API_URL + "/api/ask-an-imam/";
        fetch(api_url)
            .then((res) => res.json())
            .then(
                (result) => {
                    setAsked(result);
                },
                (error) => {
                    setError(error);
                }
            );
    }, []);
    const submitQuestion = (e) => {
        e.preventDefault();
        const newComment = { name, email, question };
        console.group(newComment);
        console.log(newComment);
        fetch(process.env.REACT_APP_API_URL + "/api/ask-an-imam/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newComment),
        })
            .then((res) => res.json())
            .then((result) => {
                window.location.reload();
            });
    };
    return (
        <div>
            <div>
                <SetOG
                    title="Ask An Imam"
                    description="Ask our imam about your questions."
                />
                <div
                    style={{ backgroundImage: `url(${AskAnImam})` }}
                    className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-xl"
                >
                    <div className="z-10 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-50">
                        <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold text-white">
                            Ask An Imam
                        </h1>
                    </div>
                </div>
                <div className="mb-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
                    <div className="col-span-1 ml-3 mb-8 xl:col-span-3 xl:mt-16">
                        <h1 className="text-3xl  font-bold xl:text-6xl">
                            Ask a Question
                        </h1>
                        <h3 className="mt-5 text-xl">
                            <a href="/login">Login</a> to ask private questions.
                        </h3>
                    </div>
                    <div className="col-span-1 xl:col-span-2">
                        <form
                            onSubmit={(e) => {
                                submitQuestion(e);
                            }}
                        >
                            <div className="w-full rounded-xl bg-white p-8 shadow-2xl">
                                <div class="mb-6">
                                    <label
                                        for="name"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Name
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        type="name"
                                        id="name"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="John Doe"
                                        required
                                    />
                                </div>
                                <div class="mb-6">
                                    <label
                                        for="email"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="mail@example.com"
                                        required
                                    />
                                </div>
                                <div class="mb-6">
                                    <label
                                        for="question"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Question
                                    </label>
                                    <textarea
                                        onChange={(e) =>
                                            setQuestion(e.target.value)
                                        }
                                        type="question"
                                        id="question"
                                        class="block h-24 w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="Type your question here"
                                        required
                                    />
                                </div>
                                <button
                                    className="bg-primary hover:bg-secondary w-full rounded-lg py-3 font-bold text-white"
                                    type="submit"
                                >
                                    Submit Question
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mb-8 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3">
                    <div className="mt-16 mb-8 lg:col-span-2 xl:col-span-3">
                        <h1 className="text-3xl font-bold xl:text-6xl">
                            Answered Questions
                        </h1>
                    </div>
                    {asked.map((askedq) => (
                        <div
                            key={askedq.id}
                            className="w-full rounded-xl bg-white shadow-2xl"
                        >
                            <a
                                href={askedq.id}
                                className="col-span-1 flex h-full w-full items-center justify-center p-10"
                            >
                                <p class="block text-sm font-bold text-gray-900 dark:text-gray-300">
                                    {askedq.question}
                                </p>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
