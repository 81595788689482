import React, { useEffect } from "react";

export default function Logout() {
    useEffect(() => {
        const user = JSON.parse(window.localStorage.getItem("user"));
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.alert("You have been logged out.");
        window.location.href = "/";
    }, []);
    return (
        <div>
            <h2 className="font-bold">You have been logged out.</h2>
            <a href="/">Go to Dashboard.</a>
        </div>
    );
}
