import React, { useState, useEffect } from "react";
import SetOG from "../Extras/SetOG";
import TitleHeader from "../Extras/TitleHeader";
import { useParams } from "react-router-dom";
import shop_bg from "../../assets/images/shop.jpg";
export default function ShopDetail() {
    const cat = useParams().cat;
    const [products, setProducts] = useState([]);
    const [error, setError] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const api_url = process.env.REACT_APP_API_URL + "/api/shop/" + cat;
        fetch(api_url)
            .then((res) => res.json())
            .then(
                (result) => {
                    setProducts(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setError(error);
                }
            );
    }, [cat]);
    return (
        <div className="overflow-hidden">
            <SetOG title={"Shop for " + cat.toUpperCase()} />
            <div
                style={{ backgroundImage: `url(${shop_bg})` }}
                className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-lg"
            >
                <div className="bg-black-500 z-10 flex h-full w-full flex-col items-center justify-center bg-opacity-30">
                    <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold text-white">
                        {"Shop for " + cat.toUpperCase()}
                    </h1>
                </div>
            </div>
            {products.length === 0 ? (
                <div className="text-center">
                    <h1 className="mt-2 text-2xl font-bold text-primary group-hover:text-orange-600">
                        No Products Found
                    </h1>
                </div>
            ) : (
                <div className="mb-8 grid w-full grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                    {products.map((product) => (
                        <a href={product.link}>
                            <div className="group products-center flex w-full py-3">
                                <div className="w-full">
                                    <div className="relative overflow-hidden rounded-xl">
                                        <div className="absolute -z-10 flex h-full w-full items-center justify-center bg-gray-400">
                                            <p>Loading...</p>
                                        </div>
                                        <img
                                            src={product.image}
                                            className="z-0 aspect-[2/3] h-full w-full overflow-hidden rounded-lg transition-all duration-300 group-hover:scale-110"
                                            alt={product.name}
                                        />
                                    </div>

                                    <div className="p-2">
                                        <h1 className="my-2 text-lg text-primary group-hover:text-orange-600">
                                            {product.name}
                                        </h1>
                                        <div className="flex justify-between text-sm">
                                            <span>{product.category}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
}
