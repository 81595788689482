import { Navigate } from "react-router-dom";
import { React, Fragment, useState, useEffect } from "react";
import { SelectorIcon } from "@heroicons/react/solid";
import { Listbox, Transition } from "@headlessui/react";
import TitleHeader from "../Extras/TitleHeader";
import SetOG from "../Extras/SetOG";
const privacy = [
    {
        value: "public",
        label: "Public",
        icon: <CheckIcon />,
    },
    {
        value: "private",
        label: "Private",
        icon: <CheckIcon />,
    },
];
export default function Dashboard() {
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [question, setQuestion] = useState(null);
    const [asked, setAsked] = useState([]);
    const [privacySelected, setPrivacySelected] = useState(privacy[0]);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(
        JSON.parse(window.localStorage.getItem("user"))
    );

    useEffect(() => {
        const api_url = process.env.REACT_APP_API_URL + "/api/ask-an-imam/";
        fetch(api_url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ user: user.id, type: "GET" }),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setAsked(result);
                },
                (error) => {
                    setError(error);
                    console.log(error);
                }
            );
    }, []);

    if (!localStorage.getItem("token")) {
        return <Navigate to="/login" />;
    }

    console.log(user);
    console.log(user.first_name);

    const submitQuestion = (e) => {
        e.preventDefault();
        const newQuestion = {
            member: user.id,
            name,
            email,
            question,
            category: privacySelected.value,
            type: "POST",
        };
        console.group(newQuestion);
        console.log(newQuestion);
        fetch(process.env.REACT_APP_API_URL + "/api/ask-an-imam/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newQuestion),
        })
            .then((res) => res.json())
            .then((result) => {
                window.location.reload();
            });
    };
    return (
        <div>
            <div>
                <SetOG title="DashBoard" />
                <TitleHeader title="Dashboard" />
                <div className="mb-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
                    <div className="col-span-1 ml-3 mb-8 xl:col-span-3 xl:mt-16">
                        <h1 className="text-3xl  font-bold xl:text-6xl">
                            Ask an Imam
                        </h1>
                        <h3 className="mt-5 text-xl">Ask private questions.</h3>
                    </div>
                    <div className="col-span-1 xl:col-span-2">
                        <form
                            onSubmit={(e) => {
                                submitQuestion(e);
                            }}
                        >
                            <div className="w-full rounded-xl bg-white p-8 shadow-2xl">
                                <div class="col-span-2">
                                    <Listbox
                                        value={privacySelected}
                                        onChange={setPrivacySelected}
                                    >
                                        <div className="relative mt-0">
                                            <Listbox.Button className="relative w-full cursor-default rounded-lg border border-gray-300 bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                <span className="block truncate">
                                                    {!privacySelected.label
                                                        ? "Select Privacy"
                                                        : privacySelected.label}
                                                </span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <SelectorIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Listbox.Button>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {privacy.map(
                                                        (person, personIdx) => (
                                                            <Listbox.Option
                                                                key={personIdx}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    `relative cursor-default select-none p-2 ${
                                                                        active
                                                                            ? "bg-amber-100 text-amber-900"
                                                                            : "text-gray-900"
                                                                    }`
                                                                }
                                                                value={person}
                                                            >
                                                                {({
                                                                    selected,
                                                                }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${
                                                                                selected
                                                                                    ? "font-medium"
                                                                                    : "font-normal"
                                                                            }`}
                                                                        >
                                                                            {
                                                                                person.label
                                                                            }
                                                                        </span>
                                                                        {selected ? (
                                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        )
                                                    )}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </Listbox>
                                </div>
                                <div class="mb-6">
                                    <label
                                        for="name"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Name
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        type="name"
                                        id="name"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="John Doe"
                                        required
                                    />
                                </div>
                                <div class="mb-6">
                                    <label
                                        for="email"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="mail@example.com"
                                        required
                                    />
                                </div>
                                <div class="mb-6">
                                    <label
                                        for="question"
                                        class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Your Question
                                    </label>
                                    <textarea
                                        onChange={(e) =>
                                            setQuestion(e.target.value)
                                        }
                                        type="question"
                                        id="question"
                                        class="block h-24 w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="Type your question here"
                                        required
                                    />
                                </div>
                                <button
                                    className="bg-primary hover:bg-secondary w-full rounded-lg py-3 font-bold text-white"
                                    type="submit"
                                >
                                    Submit Question
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mb-8 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3">
                    <div className="mt-16 mb-8 lg:col-span-2 xl:col-span-3">
                        <h1 className="text-3xl font-bold xl:text-6xl">
                            Your Questions
                        </h1>
                    </div>
                    {asked.map((askedq) => (
                        <div
                            key={askedq.id}
                            className="w-full rounded-xl bg-white shadow-2xl"
                        >
                            <a
                                href={askedq.id}
                                className="col-span-1 flex h-full w-full items-center justify-center p-10"
                            >
                                <p class="block text-sm font-bold text-gray-900 dark:text-gray-300">
                                    {askedq.question}
                                </p>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
