import { React, Fragment, useState } from "react";
import { SelectorIcon } from "@heroicons/react/solid";
import { Listbox, Transition } from "@headlessui/react";
import DONATION from "./../../assets/images/DONATION.jpg";
import FeedbackSuccess from "./../../assets/images/FeedbackSuccess.svg";
import LaptopCrashed from "./../../assets/images/LaptopCrashed.svg";
import SetOG from "./SetOG";
import { RadioGroup } from "@headlessui/react";
const currency = [
    { name: "USD", desc: "United States Dollar", symbol: "$" },
    { name: "EUR", desc: "Euro", symbol: "€" },
    { name: "GBP", desc: "Great British Pound", symbol: "£" },
    { name: "BDT", desc: "Bangladeshi Taka", symbol: "৳" },
    { name: "INR", desc: "Indian Rupee", symbol: "₹" },
];

const privacy = [
    {
        name: "Public",
        status: "public",
        desc: "Anyone can see your donation",
    },
    {
        name: "Closed",
        status: "closed",
        desc: "Your donation is public but your name is secret",
    },

    {
        name: "Anonymous",
        status: "anonymous",
        desc: "Both of your name and donation will be secret.",
    },
];

const reason = [
    {
        name: "Zaakah",
        slug: "zaakah",
    },
    {
        name: "Other",
        slug: "other",
    },
];

export default function Donate({ location }) {
    const [success, setSuccess] = useState(
        new URLSearchParams(window.location.search).get("success")
    );
    const [currencyselected, setCurrencySelected] = useState(currency[0]);
    const [privacyselected, setPrivacySelected] = useState(privacy[0]);
    const [reasonselected, setReasonSelected] = useState(reason[0]);
    return (
        <div>
            <SetOG
                title="Donate to illiyn"
                description="Donate for a better cause."
            />
            <div
                style={{ backgroundImage: `url(${DONATION})` }}
                className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-xl"
            >
                <div className="z-10 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-50">
                    <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold text-white">
                        Donate to illiyn
                    </h1>
                </div>
            </div>
            {success === null && (
                <div className="mb-8 grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-5">
                    <div className="mr-8 mb-8 xl:col-span-3">
                        <div className="card w-full rounded-2xl bg-white p-6 shadow-2xl">
                            <div className="prose">
                                <p>
                                    Allah says (
                                    <em>interpretation of the meaning</em>):
                                    &quot;If you give alms openly, it&#39;s
                                    good; but if you do it intimately and give
                                    it to the indigent, it&#39;s better for you.
                                    Allah will thus redeem your evil deeds.
                                    Allah is completely conscious of everything
                                    you do.&quot; (
                                    <em>
                                        <strong>
                                            Surat al-Baqara, Ayat 271
                                        </strong>
                                    </em>
                                    ).
                                </p>

                                <p>&nbsp;</p>

                                <p>
                                    All our activities are conducted with the
                                    money donated by you. Give your charity to
                                    Illiyn, considering it your priority. The
                                    money given by you is spent according to
                                    Islamic Shariah.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <form action="">
                            <div className="w-full rounded-xl bg-white p-8 shadow-2xl">
                                {/* Button */}
                                <RadioGroup
                                    value={privacyselected}
                                    onChange={setPrivacySelected}
                                >
                                    <RadioGroup.Label className="sr-only">
                                        Donation Privacy
                                    </RadioGroup.Label>
                                    <div className="space-y-2">
                                        {privacy.map((plan) => (
                                            <RadioGroup.Option
                                                key={plan.name}
                                                value={plan}
                                                className={({
                                                    active,
                                                    checked,
                                                }) =>
                                                    `${
                                                        active
                                                            ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                                                            : ""
                                                    }
                  ${
                      checked
                          ? "bg-slate-800 bg-opacity-75 text-white"
                          : "bg-white"
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                                                }
                                            >
                                                {({ active, checked }) => (
                                                    <>
                                                        <div className="flex w-full items-center justify-between">
                                                            <div className="flex items-center">
                                                                <div className="text-sm">
                                                                    <RadioGroup.Label
                                                                        as="p"
                                                                        className={`font-bold  ${
                                                                            checked
                                                                                ? "text-white"
                                                                                : "text-gray-900"
                                                                        }`}
                                                                    >
                                                                        {
                                                                            plan.name
                                                                        }
                                                                    </RadioGroup.Label>
                                                                    <RadioGroup.Description
                                                                        as="span"
                                                                        className={`inline ${
                                                                            checked
                                                                                ? "text-sky-100"
                                                                                : "text-gray-500"
                                                                        }`}
                                                                    >
                                                                        <span>
                                                                            {
                                                                                plan.desc
                                                                            }
                                                                        </span>
                                                                    </RadioGroup.Description>
                                                                </div>
                                                            </div>
                                                            {checked && (
                                                                <div className="flex-shrink-0 text-white">
                                                                    <CheckIcon className="h-6 w-6" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        ))}
                                    </div>
                                </RadioGroup>

                                <div className="my-6">
                                    {privacyselected.status === "public" && (
                                        <div>
                                            <div class="mb-6">
                                                <label
                                                    for="name"
                                                    class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                                >
                                                    Your Name
                                                </label>
                                                <input
                                                    type="name"
                                                    id="name"
                                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    placeholder="John Doe"
                                                    required
                                                />
                                            </div>
                                            <div class="mb-6">
                                                <label
                                                    for="email"
                                                    class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                                >
                                                    Your email
                                                </label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    placeholder="name@gmail.com"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="my-6 grid grid-cols-6 gap-x-3">
                                    <label
                                        for="email"
                                        class="col-span-5 mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                    >
                                        Select Currency and Amount
                                    </label>
                                    <div class="col-span-2">
                                        <Listbox
                                            value={currencyselected}
                                            onChange={setCurrencySelected}
                                        >
                                            <div className="relative mt-0">
                                                <Listbox.Button className="relative w-full cursor-default rounded-lg border border-gray-300 bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                    <span className="block truncate">
                                                        {currencyselected.name +
                                                            " - " +
                                                            currencyselected.symbol}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <SelectorIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>
                                                <Transition
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {currency.map(
                                                            (
                                                                person,
                                                                personIdx
                                                            ) => (
                                                                <Listbox.Option
                                                                    key={
                                                                        personIdx
                                                                    }
                                                                    className={({
                                                                        active,
                                                                    }) =>
                                                                        `relative cursor-default select-none p-2 ${
                                                                            active
                                                                                ? "bg-amber-100 text-amber-900"
                                                                                : "text-gray-900"
                                                                        }`
                                                                    }
                                                                    value={
                                                                        person
                                                                    }
                                                                >
                                                                    {({
                                                                        selected,
                                                                    }) => (
                                                                        <>
                                                                            <span
                                                                                className={`block truncate ${
                                                                                    selected
                                                                                        ? "font-medium"
                                                                                        : "font-normal"
                                                                                }`}
                                                                            >
                                                                                {person.name +
                                                                                    " - " +
                                                                                    person.symbol}
                                                                            </span>
                                                                            {selected ? (
                                                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                                    <CheckIcon
                                                                                        className="h-5 w-5"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            )
                                                        )}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </Listbox>
                                    </div>

                                    <div class="col-span-4">
                                        <input
                                            type="number"
                                            id="type"
                                            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                            placeholder="9999.99"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="my-6">
                                    <div class="mb-6">
                                        <label
                                            for="email"
                                            class="mb-2 block text-sm font-bold text-gray-900 dark:text-gray-300"
                                        >
                                            Select Reason For Donation
                                        </label>

                                        <Listbox
                                            value={reasonselected}
                                            onChange={setReasonSelected}
                                        >
                                            <div className="relative mt-0">
                                                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-3 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                    <span className="block truncate">
                                                        {"Currency: " +
                                                            reasonselected.name}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <SelectorIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>
                                                <Transition
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {reason.map(
                                                            (
                                                                person,
                                                                personIdx
                                                            ) => (
                                                                <Listbox.Option
                                                                    key={
                                                                        personIdx
                                                                    }
                                                                    className={({
                                                                        active,
                                                                    }) =>
                                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                            active
                                                                                ? "bg-amber-100 text-amber-900"
                                                                                : "text-gray-900"
                                                                        }`
                                                                    }
                                                                    value={
                                                                        person
                                                                    }
                                                                >
                                                                    {({
                                                                        selected,
                                                                    }) => (
                                                                        <>
                                                                            <span
                                                                                className={`block truncate ${
                                                                                    selected
                                                                                        ? "font-medium"
                                                                                        : "font-normal"
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    person.name
                                                                                }
                                                                            </span>
                                                                            {selected ? (
                                                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                                    <CheckIcon
                                                                                        className="h-5 w-5"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            )
                                                        )}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </Listbox>
                                    </div>
                                </div>
                                <button
                                    className="bg-primary hover:bg-secondary w-full rounded-lg py-3 font-bold text-white"
                                    type="submit"
                                >
                                    Proceed to Pay
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {success === "true" && (
                <div className="my-16 flex flex-col items-center justify-center">
                    <img src={FeedbackSuccess} className="w-48" alt="" />
                    <h1 className="my-8 text-5xl font-bold">
                        Payment Successful
                    </h1>
                    <p className="text-center text-lg">
                        Your donation has been successfully processed.
                        <br /> Thank you for being with us
                    </p>
                    <div className="my-8">
                        <a
                            className="bg-primary hover:bg-secondary rounded-xl py-3 px-6 text-white"
                            href=""
                        >
                            Go to Home
                        </a>
                    </div>
                </div>
            )}
            {success === "false" && (
                <div className="my-16 flex flex-col items-center justify-center">
                    <img src={LaptopCrashed} className="w-48" alt="" />
                    <h1 className="my-8 text-5xl font-bold">Payment Failed</h1>
                    <p className="text-center text-lg">
                        Your donation couldn't be processed.
                        <br /> You can always try again.
                    </p>
                    <div className="my-8">
                        <a
                            className="bg-primary hover:bg-secondary rounded-xl py-3 px-6 text-white"
                            href="/donate"
                        >
                            Try again
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}

function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
