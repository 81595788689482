import React from "react";
import bg from "./../../assets/images/bg.png";

export default function TitleHeader({ title, tagline }) {
    return (
        <div
            style={{ backgroundImage: `url(${bg})` }}
            className="relative my-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-xl bg-cover bg-center shadow-lg"
        >
            <div className="z-10 flex h-full w-full flex-col items-center justify-center bg-white bg-opacity-30">
                <h1 className="text-shadow-white mx-8 pb-4 text-4xl font-bold">
                    {title}
                </h1>
                <p className="font-bold">{tagline}</p>
            </div>
        </div>
    );
}
